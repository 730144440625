

import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow.js";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow.js";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow.js";

import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow.js";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow.js";
//import BoolUpdateRow from "@/portals/shared/cell/bool/BoolUpdateRow.js";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow.js";
import TextAreaUpdateRow from "@/portals/shared/cell/textarea/TextAreaUpdateRow.js";
//import ChecklistItem from "@/domain/model/checklist/item/CheckListItem.js";
import ChecklistItemUpdateRow from "@/portals/shared/cell/checklist/item/ChecklistItemUpdateRow.js";
import ChecklistItemOptionUpdateRow from "@/portals/shared/cell/checklist/item/option/ChecklistItemOptionUpdateRow.js";

import MC from "@/domain/session/MC.js";

import Checklist from '@/domain/model/checklist/CheckList.js';

export default class AddUtils {
  
  static Fields = {
    NAME: "checklistName",
    DESCRIPTION: "checklistDescription",
    SaveButton: "saveButton",
    CancelButton: "cancelButton",
    
    AddQuestionButton: "addQuestionButton",
    RemoveQuestionButton: "removeQuestionButton",

    UpQuestionButton: "upQuestionButton",
    DownQuestionButton: "downQuestionButton",

    AddOptionButton: "addOptionButton",
    RemoveOptionButton: "removeOptionButton",
    
    UpOptionButton: "upOptionButton",
    DownOptionButton: "downOptionButton",

    QuestionBox: "addQuestionBox",
  }
  
  static buildRowData(domain, panel, tableData) {
    var au = new AddUtils(domain, panel, tableData);
    au.start();
  }

  constructor(domain, panel, tableData) {
    this._panel = panel;
    this._tableData = tableData;
    this._domain = domain;
    this._logger = domain.logger();
    this.MC = new MC();
  }
  
  panel() {
    return this._panel;
  }
  
  checklist() {
    return this.panel().model().checklist();
  }
  
  logger() {
    return this._logger;
  }
  
  start() {
    
    this.addRow(new TitleDisplayRow()
        .withTitle(this.MC.CheckList.Add.Title.value())
        );
    this.addRow(new RowDisplayRow()
        .withBorder(true)
        .withChildren([
                       new StringUpdateRow()
                          .withWidth(5)
                          .withName(this.MC.CheckList.Add.NameLabel.value())
                          .withObject(Checklist.MODEL_NAME)
                          .withId(this.checklist().id())
                          .withField(Checklist.Fields.Name)
                          .withKey(Checklist.Fields.Name)
                          .withMandatoryValue(true)
                          .withDefaultValue(this.checklist().name())
                          .withHint(this.MC.CheckList.Add.NameHint.value()),
                        
                        new TextAreaUpdateRow()
                          .withWidth(10)
                          .withName(this.MC.CheckList.Add.DescriptionLabel.value())
                          .withObject(Checklist.MODEL_NAME)
                          .withDefaultValue(this.checklist().description())
                          .withId(this.checklist().id())
                          .withField(Checklist.Fields.Description)
                          .withKey(Checklist.Fields.Description)
                          .withHint(this.MC.CheckList.Add.DescriptionHint.value()),
                         
                       ])
        );
    
    this.addRow(new TitleDisplayRow()
        .withTitle(this.MC.CheckList.Add.CheckListItemTitle.value())
        .withSubtitle(true)
        );
    
    var itemChildren = this.createItemChildren();
    var childRows = [];
    childRows.push(new StringDisplayRow()
        .withAlignLeft()
        .withStyleEven()
        .withValue(this.MC.CheckList.QuestionDescription.value()));
    
    childRows.push(...itemChildren);
    
    this.addRow(new BoxDisplayRow()
        .withField(AddUtils.Fields.QuestionBox)
        .withBorder(true)
        .withChildren(childRows)
        );
    
    this.addRow(new SpacerDisplayRow());
    
    this.addRow(new RowDisplayRow()
        .withBorder(false)
        .withChildren([
          new SpacerDisplayRow()
            .withWidth(4),
            
          new ButtonDisplayRow()
            .withWidth(3)
            .withName(this.MC.CheckList.CancelButton.value())
            .withField(AddUtils.Fields.CancelButton),
            
          new SpacerDisplayRow()
            .withWidth(1),

          new ButtonDisplayRow()
            .withBackgroundPrimaryAction()
            .withName(this.MC.CheckList.SaveButton.value())
            .withField(AddUtils.Fields.SaveButton)
            .withWidth(3),

          new SpacerDisplayRow()
            .withWidth(4),

        ]));
  }
  
  getChecklistItems() {
    return this.checklist().items();
  }
  
  createItemChildren() {
    var kids = [];
    
    var itemMap = this.getChecklistItems();
    var items = itemMap.sortByIndex();
    for (var i = 0 ; i < items.length; i++) {
      var item = items[i];
      
      this.logger().info("{0} {1}", [item.index(), item.name()]);
      
      var grandKids = this.createItemOptionChildren(item);
      
      var itemRows = [];
      
      itemRows.push(new ChecklistItemUpdateRow()
           .withItem(item)
           .withIndex(i)
           .withMandatoryValue(true)
           .withMaxIndex(items.length)
           .withChildren(grandKids));
           
      var itemRow = new BoxDisplayRow()
          .withBorder(true)
          .withChildren(itemRows)
      kids.push(itemRow);
    }
    var lastRow = new RowDisplayRow()
      .withChildren([
          new SpacerDisplayRow()
            .withWidth(13),
            
          new ButtonDisplayRow()
            .withWidth(2)
            .withName(this.MC.CheckList.AddQuestionButton.value())
            .withField(AddUtils.Fields.AddQuestionButton)
            ]);
    
    kids.push(lastRow);
    return kids;
  }
  
  getChecklistItemOptions(item) {
    return item.options();
  }
  
  createItemOptionChildren(item) {
    var kids = [];
    var optionMap = this.getChecklistItemOptions(item);
    var options = optionMap.sortByIndex();
    
    for (var i = 0 ; i < options.length; i++) {
      var option = options[i];
      var row = new ChecklistItemOptionUpdateRow()
          .withMandatoryValue(true)
          .withIndex(i)
          .withMaxIndex(options.length)
          .withOption(option)
          .withRemove(!item.isTypeBool());
      kids.push(row);
    }
    
    if (item.isTypeList() && !item.isTypeBool()) {
      var lastRow = new RowDisplayRow()
          .withChildren([
              new SpacerDisplayRow()
                .withWidth(13),
                
              new ButtonDisplayRow()
                .withWidth(2)
                .withName(this.MC.CheckList.AddOptionButton.value())
                .withField(AddUtils.Fields.AddOptionButton)
                ]);
      kids.push(lastRow);
    }
    
    return kids;
  }
  
  addRow(row) {
    this._tableData.push(row);
  }
  
}