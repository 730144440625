


import AddModel from "./AddModel.js";
import EditUtils from "./EditUtils.js";

//import StringUtils  from '@/utils/StringUtils.js';

//import Checklist from '@/domain/model/checklist/CheckList.js';
//import ChecklistItem from '@/domain/model/checklist/item/CheckListItem.js';
//import ChecklistItemOption from '@/domain/model/checklist/item/option/CheckListItemOption.js';

export default class EditModel extends AddModel {
  
  constructor(panel, checklist) {
    super(panel, checklist);
  }
  
  buildRowData(newData) {
    var au = new EditUtils(this.domain(), this.panel(), newData);
    au.start();
  }
}