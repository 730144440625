


import AddUtils from "./AddUtils.js";

//import StringUtils  from '@/utils/StringUtils.js';

//import Checklist from '@/domain/model/checklist/CheckList.js';
//import ChecklistItem from '@/domain/model/checklist/item/CheckListItem.js';
//import ChecklistItemOption from '@/domain/model/checklist/item/option/CheckListItemOption.js';

export default class EditUtils extends AddUtils {
  
  constructor(domain, panel, tableData) {
    super(domain, panel, tableData);
  }
  
  getChecklistItems() {
    var items = super.getChecklistItems();
    return items;
  }

  getChecklistItemOptions(item) {
    var options = super.getChecklistItemOptions(item);
    return options;
  }
}